<template>
	<div class="find-password mx-auto">
		<v-card class="d-flex align-start flex-column mx-auto" elevation="0">
			<h1 class="heading">{{ $t('findPassword.title') }}</h1>
			<p class="text-sm" v-html="$t('findPassword.subTitle')"></p>
			<div class="form">
				<v-stepper v-model="activeStep" elevation="0" class="pa-0">
					<v-stepper-items>
						<v-stepper-content step="1" class="pa-1">
							<validation-observer ref="observer" v-slot="{ valid }">
								<form @submit.prevent="verification" ref="form">
									<validation-provider
										v-slot="{ errors }"
										name="uid"
										rules="required"
									>
										<v-text-field
											v-model="findPasswordForm.uid"
											:error-messages="errors"
											:label="$t('findPassword.findPasswordForm.uid')"
											required
										/>
									</validation-provider>
									<validation-provider
										v-slot="{ errors }"
										name="phone"
										rules="required"
									>
										<v-text-field
											v-model="findPasswordForm.phoneNumber"
											:error-messages="errors"
											@keypress="allowOnlyNumber($event)"
											:label="$t('findPassword.findPasswordForm.phoneNumber')"
											required
										/>
									</validation-provider>
									<validation-provider
										v-slot="{ errors }"
										name="name"
										rules="required"
									>
										<v-text-field
											v-model="findPasswordForm.name"
											:error-messages="errors"
											:label="$t('findPassword.findPasswordForm.name')"
											required
										/>
									</validation-provider>
									<v-btn
										class="button full-width"
										type="submit"
										width="100%"
										color="primary"
										large
										:disabled="!valid"
										:loading="verificationLoading"
									>
										{{ $t('findPassword.findPasswordForm.submit') }}
									</v-btn>
								</form>
							</validation-observer>
						</v-stepper-content>

						<v-stepper-content step="2" class="pa-1">
							<validation-observer ref="observer" v-slot="{ valid }">
								<form @submit.prevent="resetPassword" ref="form">
									<validation-provider
										v-slot="{ errors }"
										name="password"
										rules="required|password"
									>
										<v-text-field
											v-model="findPasswordForm.password"
											:type="isPasswordVisible ? 'text' : 'password'"
											:error-messages="errors"
											label="새로운 비밀번호를 입력하세요"
											:append-icon="
												isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'
											"
											@click:append="isPasswordVisible = !isPasswordVisible"
											dense
											outlined
											required
										/>
									</validation-provider>
									<validation-provider
										v-slot="{ errors }"
										name="repassword"
										rules="required|repassword:@password"
									>
										<v-text-field
											v-model="findPasswordForm.repassword"
											:type="isPasswordVisible ? 'text' : 'password'"
											:error-messages="errors"
											label="새로운 비밀번호를 재입력하세요"
											:append-icon="
												isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'
											"
											@click:append="isPasswordVisible = !isPasswordVisible"
											dense
											outlined
											required
										/>
									</validation-provider>
									<v-btn
										class="button full-width"
										type="submit"
										width="100%"
										color="primary"
										:disabled="!valid"
										:loading="resetPasswordLoading"
									>
										변경하기
									</v-btn>
								</form>
							</validation-observer>
						</v-stepper-content>
					</v-stepper-items>
				</v-stepper>
			</div>
		</v-card>
	</div>
</template>

<script>
import { ref, reactive } from '@vue/composition-api'

import store from '@/store'
import { successSwal, warningSwal } from '@/plugins/swalMixin'

import AuthService from '@/services/AuthService'

export default {
	components: {},
	setup() {
		const activeStep = ref(1)
		const verificationLoading = ref(false)
		const resetPasswordLoading = ref(false)
		const isPasswordVisible = ref(false)
		const findPasswordForm = reactive({
			uid: '',
			name: '',
			phoneNumber: '',
			password: '',
			newPassword: '',
			iamportUid: '',
		})

		const allowOnlyNumber = $event => {
			const keyCode = $event.keyCode ? $event.keyCode : $event.which
			if (keyCode < 48 || keyCode > 57) {
				$event.preventDefault()
			}
		}

		const isMobile = () => {
			if (
				/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
					navigator.userAgent,
				)
			) {
				return true
			}
		}

		const callback = async response => {
			/* 3. 콜백 함수 정의하기 */
			const { success, imp_uid, error_msg } = response

			if (success) {
				successSwal('본인인증이 완료되었습니다')

				findPasswordForm.iamportUid = imp_uid
				activeStep.value = 2
			} else {
				verificationLoading.value = false
				warningSwal(error_msg)
			}
		}

		const verification = async () => {
			verificationLoading.value = true
			try {
				/* 1. 가맹점 식별하기 */
				const { IMP } = window
				IMP.init(process.env.VUE_APP_MERCHANT_ID_CODE)

				/* 2. 본인인증 데이터 정의하기 */
				const data = {
					merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
					name: findPasswordForm.name,
					phone: findPasswordForm.phoneNumber,
					company: 'https://itmarketer.org/',
					popup: isMobile() ? true : false,
				}

				/* 4. 본인인증 창 호출하기 */
				IMP.certification(data, callback)
			} catch (e) {
				console.log(e)
			}
		}

		const resetPassword = async () => {
			try {
				await AuthService.resetPasswordByCi({
					iamportUid: findPasswordForm.iamportUid,
					uid: findPasswordForm.uid,
					phoneNumber: findPasswordForm.phoneNumber,
					password: findPasswordForm.password,
				})
				successSwal({
					html: '비밀번호가 변경되었습니다',
					allowOutsideClick: false,
				})
			} catch (e) {
				warningSwal(
					e.response.status === 400
						? e.response.data.message
						: '비밀번호를 변경하는데 문제가 발생했습니다',
				)
			}
		}

		store.dispatch('app/setIsLogoWhite', false)

		return {
			activeStep,
			findPasswordForm,
			verificationLoading,
			resetPasswordLoading,
			isPasswordVisible,

			verification,
			allowOnlyNumber,
			resetPassword,
		}
	},
}
</script>
<style lang="scss" scoped>
.find-password {
	height: 100vh;
	margin-top: 10em;
	padding-top: 36px;
	padding-left: 20px;
	padding-right: 20px;

	.v-card {
		padding-top: 44px;
		padding-left: 34px;
		padding-right: 34px;
		padding-bottom: 34px;
		width: 440px;
		max-width: 440px;
		border: 1px solid #e4e5ed;
		width: 100%;

		::v-deep {
			.v-stepper__wrapper {
				overflow: visible;
			}
		}

		.form {
			width: 100%;
		}

		@media screen and (max-width: 768px) {
			padding: 22px;
		}

		@media screen and (max-width: 480px) {
			width: 100%;
		}

		h1.heading {
			font-size: 27px;
			font-weight: normal;
			color: #303441;
			line-height: normal;
			margin-bottom: 20px;
			margin-top: 0;

			@media screen and (max-width: 768px) {
				font-size: 20px;
			}
		}
	}
}
</style>
